import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, QuickBuyProduct } from "../../types";

const QuickbuyClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.marketing,
  eventName: "Product Page Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createQuickBuyClickedPageEventPayload =
  eventTrackPayloadCreator<QuickBuyProduct>(QuickbuyClickedAnalyticsEvent);
