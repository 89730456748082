import { IconContainerProps } from "../IconContainer";

export enum IconButtonType {
  "outline" = "outline",
  "no_outline" = "no_outline",
  "no_outline_no_hover" = "no_outline_no_hover",
  "multiple_outline" = "multiple_outline",
  "transparent" = "transparent",
}

export type IconButtonTypes = keyof typeof IconButtonType;

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /**
   * Must provide an accessible label for the icon button.
   */
  ["aria-label"]: string;
  className?: string;
  iconContainerProps?: IconContainerProps;
  buttonType?: IconButtonTypes;
  /**
   * Indicates that the button should have a border-radius md (6px)
   */
  isRounded?: boolean;
  /**
   * Indicates that the button is currently active.
   */
  isActive?: boolean;
};
