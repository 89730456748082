import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
export interface GamePagePayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
}

const GamePageViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Page Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGamePageViewedPageEventPayload =
  eventPagePayloadCreator<GamePagePayload>(GamePageViewedAnalyticsEvent);
