import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
export interface GameQuestPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
}

const GameQuestViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Quest Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameQuestViewedPageEventPayload =
  eventPagePayloadCreator<GameQuestPayload>(GameQuestViewedAnalyticsEvent);
