import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface SeeDemoClickedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
}

const SeeDemoClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Experiment See Demo Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createSeeDemoClickedPageEventPayload =
  eventPagePayloadCreator<SeeDemoClickedPayload>(SeeDemoClickedAnalyticsEvent);
