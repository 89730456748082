import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface Game3DModelEngagedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
}

const Game3DModelEngagedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game 3D Model Engaged",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGame3DModelEngagedPageEventPayload =
  eventPagePayloadCreator<Game3DModelEngagedPayload>(
    Game3DModelEngagedAnalyticsEvent,
  );
