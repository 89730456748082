import { CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { getPageTypeFromPathname } from "../../helpers/helpers";
import { EventData, EventPayload } from "../../types";

export interface ContentPagePayload extends EventPayload {
  content?: {
    title: string;
  };
}

const ContentPageViewedAnalyticsEvent: EventData = {
  pageType: getPageTypeFromPathname(),
  eventName: "Content Page Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createContentPageViewedPageEventPayload =
  eventPagePayloadCreator<ContentPagePayload>(ContentPageViewedAnalyticsEvent);
