import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

const SwimlaneScrolledEvent: EventData = {
  eventName: "What's New Carousel Scrolled",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createSwimlaneScrolledTrackEventPayload = () => {
  return eventTrackPayloadCreator(SwimlaneScrolledEvent);
};
