import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface GameVoteUnClickedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
}

const GameVoteUnClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Vote UnClicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameVoteUnClickedPageEventPayload =
  eventPagePayloadCreator<GameVoteUnClickedPayload>(
    GameVoteUnClickedAnalyticsEvent,
  );
