import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ErrorSiteTakeover404Payload {}

const ErrorSiteTakeover404ViewedAnalyticsEvent: EventData = {
  pageDetail: "404 Error Site Takover",
  pageType: PAGE_TYPE.landing,
  eventName: "404 Error Site Takeover Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createErrorSiteTakeover404ViewedPageEventPayload =
  eventPagePayloadCreator<ErrorSiteTakeover404Payload>(
    ErrorSiteTakeover404ViewedAnalyticsEvent,
  );
