import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AchievementsPayload {}

const AchievementsViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.achievements,
  eventName: "Achievements Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createAchievementsViewedPageEventPayload =
  eventPagePayloadCreator<AchievementsPayload>(
    AchievementsViewedAnalyticsEvent,
  );
