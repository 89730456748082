import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface GamePagePayload extends EventPayload {
  content?: {
    title: string;
  };
}

const GamePartnerPageViewedAnalyticsEvent: EventData = {
  pageType: PAGE_TYPE.gamePartner,
  eventName: "Game Partner Page",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGamePartnerPageViewedPageEventPayload =
  eventPagePayloadCreator<GamePagePayload>(GamePartnerPageViewedAnalyticsEvent);
