import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IntroductionSequenceSkippedPayload {}

const IntroductionSequenceSkippedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Introduction Sequence Skipped",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createIntroductionSequenceSkippedTrackEventPayload =
  eventPagePayloadCreator<IntroductionSequenceSkippedPayload>(
    IntroductionSequenceSkippedAnalyticsEvent,
  );
