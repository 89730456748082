import { PAGE_TYPE, CLASSIFICATION_TYPE, INITIATIVE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "./types";

export type ProductPagePayload = Omit<
  BasePreOrderAnalyticsPayload,
  "orderId" | "orderType"
>;

const ProductPageViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.checkout,
  eventName: "Product Page Viewed",
  initiative: INITIATIVE.preOrder,
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const createProductPageViewedPageEventPayload =
  eventPagePayloadCreator<ProductPagePayload>(ProductPageViewedAnalyticsEvent);
