import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
export interface GameQuestStartedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
  message: {
    title: string;
    body: string;
  };
}

const GameQuestStartedViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Quest Started",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameQuestStartedViewedPageEventPayload =
  eventPagePayloadCreator<GameQuestStartedPayload>(
    GameQuestStartedViewedAnalyticsEvent,
  );
