import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../../constants";
import { eventTrackPayloadCreator } from "../../../../helpers/eventPayloadCreator";
import { EventData } from "../../../../types";
import { BasePreOrderAnalyticsPayload } from "../../../page/preorder/types";

const UserHitPurchaseLimitAnalyticsEvent: EventData = {
  pageDetail: "Hang Tight Page",
  pageType: PAGE_TYPE.checkout,
  eventName: "User Hit Purchase Limit",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const PreorderUserHitPurchaseLimitAnalyticsEventPayload =
  eventTrackPayloadCreator<BasePreOrderAnalyticsPayload>(
    UserHitPurchaseLimitAnalyticsEvent,
  );
