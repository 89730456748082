import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "../../page/preorder/types";

export type OrderCancellationConfirmedEvent = BasePreOrderAnalyticsPayload & {
  pageDetail?: string;
};

const OrderCancellationConfirmedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.checkout,
  eventName: "Order Cancel Confirmed",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const OrderCancellationConfirmedEventPayload =
  eventTrackPayloadCreator<OrderCancellationConfirmedEvent>(
    OrderCancellationConfirmedAnalyticsEvent,
  );
