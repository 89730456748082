import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface MarketingBannerPrimaryCtaClickedPayload extends EventPayload {
  content?: {
    title: string;
  };
}

const DefaultEventData: Omit<EventData, "pageType"> = {
  pageDetail: "",
  eventName: "hero-primary-cta-clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createMarketingBannerPrimaryCtaClickedTrackEventPayload = (
  pageType: PAGE_TYPE,
) => {
  return eventTrackPayloadCreator<MarketingBannerPrimaryCtaClickedPayload>({
    pageType,
    ...DefaultEventData,
  });
};
