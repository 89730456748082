import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "./types";

export type HangTightViewedPayload = BasePreOrderAnalyticsPayload & {
  pageDetail?: string;
};

const HangTightViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.checkout,
  eventName: "Hang Tight Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createOrderPendingHangTightViewedPageEventPayload =
  eventPagePayloadCreator<HangTightViewedPayload>(
    HangTightViewedAnalyticsEvent,
  );
