import { CLASSIFICATION_TYPE, INITIATIVE, PAGE_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "./types";

export type ProductMarketingContentViewedPageEventPayload = Omit<
  BasePreOrderAnalyticsPayload,
  "orderId" | "orderType"
>;

const ProductMarketingContentViewedEvent: EventData = {
  pageType: PAGE_TYPE.pdp,
  eventName: "Product Marketing Content Viewed",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
  initiative: INITIATIVE.preOrder,
};

export const createProductMarketingContentViewedPageEventPayload =
  eventPagePayloadCreator<ProductMarketingContentViewedPageEventPayload>(
    ProductMarketingContentViewedEvent,
  );
