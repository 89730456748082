import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import {
  CollectionProperties,
  EventData,
  EventPayload,
  TokenProperties,
} from "../../types";

export interface DownloadReadyTrackEventPayload extends EventPayload {
  token: TokenProperties;
  collections: CollectionProperties;
}

const DownloadReadyAnalyticsEvent: EventData = {
  pageDetail: "download",
  pageType: PAGE_TYPE.tlp,
  eventName: "Download Ready",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createDownloadReadyTrackEventPayload =
  eventTrackPayloadCreator<DownloadReadyTrackEventPayload>(
    DownloadReadyAnalyticsEvent,
  );
