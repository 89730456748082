import clsx from "clsx";
import { IconButtonProps, IconButtonType, IconButtonTypes } from "./types";

// Shared between all icon buttons
export const COMMON_ICON_BUTTON_STYLES = clsx(
  "text-gray-950 p-2.5 transition-colors duration-200 ease-in-out dark:text-gray-100",
  "disabled:pointer-events-none disabled:text-gray-425 dark:disabled:text-gray-425", // disabled states
  "selected:text-blue-500 dark:selected:text-green-500", // selected states
);

// Shared outline styles
const ICON_BUTTON_OUTLINE_STYLES =
  "border border-gray-950 dark:border-gray-100 disabled:border-gray-425 dark:disabled:border-gray-425";

// Shared hover styles
const ICON_BUTTON_HOVER_STYLES =
  "hover:bg-green-500 dark:hover:text-gray-950 hover:selected:text-gray-950 hover:dark:selected:text-gray-950";

export const MAP_ICON_BUTTON_TYPE_TO_CLASSNAME = {
  /*
   * Transparent is used for media icons
   */
  [IconButtonType.transparent]: clsx(
    COMMON_ICON_BUTTON_STYLES,
    "backdrop-blur-sm",
    "bg-white bg-opacity-50 hover:bg-opacity-100",
    "dark:bg-gray-950 dark:bg-opacity-50 dark:hover:bg-opacity-100",
  ),
  [IconButtonType.outline]: clsx(
    COMMON_ICON_BUTTON_STYLES,
    ICON_BUTTON_OUTLINE_STYLES,
    ICON_BUTTON_HOVER_STYLES,
  ),
  [IconButtonType.no_outline]: clsx(
    COMMON_ICON_BUTTON_STYLES,
    ICON_BUTTON_HOVER_STYLES,
  ),
  [IconButtonType.no_outline_no_hover]: clsx(COMMON_ICON_BUTTON_STYLES),
  /**
   * Used when multiple buttons are grouped together in a row
   */
  [IconButtonType.multiple_outline]: clsx(
    COMMON_ICON_BUTTON_STYLES,
    ICON_BUTTON_OUTLINE_STYLES,
    ICON_BUTTON_HOVER_STYLES,
    "disabled:bg-gray-900 dark:disabled:border-white first:border-r-0 last:border-l-0",
  ),
};

export function getIconButtonStyles(
  props: Pick<IconButtonProps, "buttonType" | "isActive" | "isRounded">,
) {
  return clsx(
    props.isActive && "is-selected",
    MAP_ICON_BUTTON_TYPE_TO_CLASSNAME[
      props.buttonType ?? IconButtonType.outline
    ],
    isOutlineButton(props.buttonType) && props.isRounded && "rounded-md",
  );
}

const isOutlineButton = (buttonType?: IconButtonTypes) => {
  return buttonType === IconButtonType.outline;
};
