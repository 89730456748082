import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface GamePartnerPageCTAClickedPayload extends EventPayload {
  content: {
    title: string;
  };
}

const GamePartnerPageCTAClickedEventData: EventData = {
  pageType: PAGE_TYPE.gamePartner,
  eventName: "Game Partner Page CTA Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGamePartnerPageCTAClickedEventPayload =
  eventTrackPayloadCreator<GamePartnerPageCTAClickedPayload>(
    GamePartnerPageCTAClickedEventData,
  );
