import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface SwimlaneLinkClickedPayload extends EventPayload {
  content?: {
    title: string;
  };
}

const SwimlaneLinkClickedEvent: EventData = {
  eventName: "Content Carousel Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createSwimlaneLinkClickedTrackEventPayload = () => {
  return eventTrackPayloadCreator<SwimlaneLinkClickedPayload>(
    SwimlaneLinkClickedEvent,
  );
};
