import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface AchievementDetailsPayload extends EventPayload {
  achievementName?: string;
  achievementId?: string;
  achievementDescription?: string;
  achievementDate?: string;
}

const AchievementDetailsViewedAnalyticsEvent: EventData = {
  pageDetail: "details",
  pageType: PAGE_TYPE.achievements,
  eventName: "Achievement Details Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createAchievementDetailsViewedPageEventPayload =
  eventPagePayloadCreator<AchievementDetailsPayload>(
    AchievementDetailsViewedAnalyticsEvent,
  );
