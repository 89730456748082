import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
export interface GameQuestDetailsClickedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
  message: {
    title: string;
    body: string;
  };
}

const GameQuestDetailsClickedViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Quest Details Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameQuestDetailsClickedViewedPageEventPayload =
  eventPagePayloadCreator<GameQuestDetailsClickedPayload>(
    GameQuestDetailsClickedViewedAnalyticsEvent,
  );
