import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export type VirtualAvatarFitSharedTrackEventPayload = EventPayload;

export const createVirtualAvatarFitSharedTrackEventPayload = (
  eventData: EventData,
) => {
  return eventTrackPayloadCreator<VirtualAvatarFitSharedTrackEventPayload>(
    eventData,
  );
};
