import { EVENT_TYPE, PAGE_TYPE, CLASSIFICATION_TYPE } from "../constants";
import { User } from "../types";

export const getPageName = (pageType?: PAGE_TYPE, pageDetail?: string) =>
  ["nvs", pageType, pageDetail]
    .filter((x) => x)
    .join(">")
    .toLowerCase();

export const slugify = (x: string) => x.replace(/\s+/g, "-").toLowerCase();

export const createSchemaLink = (
  classificationType: CLASSIFICATION_TYPE,
  pageType: PAGE_TYPE,
  eventType: EVENT_TYPE,
  eventName: string,
) => {
  const link =
    "https://www.nike.com/assets/measure/schemas/digital-product/nvs/platform/web/classification/" +
    classificationType +
    "/experience/" +
    pageType +
    "/event-type/" +
    eventType +
    "/event-name/" +
    slugify(eventName) +
    "/version/LATEST/schema.json";
  return link;
};

export const getGlobalProperties = (
  country?: string,
  user?: User,
  anonymousId?: string,
  os?: { name?: string; version?: string },
) => {
  const loginStatus = user !== undefined && JSON.stringify(user) !== "{}";
  let membershipStatus;
  loginStatus ? (membershipStatus = "active") : (membershipStatus = "inactive");
  return {
    anonymousId: anonymousId || "",
    context: {
      os: os,
    },
    consumer: {
      upmId: user?.upmId || "guest",
      optimizelyUserId: user?.upmId || "guest",
      swooshHandle: user?.swooshHandle || "guest",
      swooshMemberNumber: user?.memberNumber || "guest",
      swooshMembershipStatus: membershipStatus,
      loginStatus: loginStatus,
    },
    locale: {
      country: country,
    },
  };
};

/**
 * Small utility to convert pathname to arrow format (e.g. /drops/low-poly-adventure -> drops>low-poly-adventure)
 * @param pathname - string containing a pathname (e.g. /drops/low-poly-adventure)
 * @returns string - a string with the pathname converted to arrow format (e.g. drops>low-poly-adventure)
 */
export function convertPathnameToArrowFormat(pathname: string): string {
  return pathname.replace(/^\//, "").replace(/\/+/g, ">");
}

/**
 * A small utility to help get the page type from the current pathname
 * @returns PAGE_TYPE - the page type (in arrow format) based on the current pathname or undefined if on the server
 */
export function getPageTypeFromPathname(): PAGE_TYPE {
  // To prevent next.js from being angry when bundling server code we only use window if window is defined
  const isBrowser = typeof window !== "undefined";

  return (
    isBrowser
      ? convertPathnameToArrowFormat(window.location.pathname)
      : undefined
  ) as PAGE_TYPE;
}
