import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface VirtualAvatarInteractedTrackEventPayload extends EventPayload {
  products: [
    {
      name: string;
    },
  ];
}

export const createVirtualAvatarInteractedTrackEventPayload = (
  eventData: EventData,
) => {
  return eventTrackPayloadCreator<VirtualAvatarInteractedTrackEventPayload>(
    eventData,
  );
};
