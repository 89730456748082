import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SiteTakeover404Payload {}

const SiteTakeover404ViewedAnalyticsEvent: EventData = {
  pageDetail: "404 Site",
  pageType: PAGE_TYPE.landing,
  eventName: "404 Site Takeover Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createSiteTakeover404ViewedPageEventPayload =
  eventPagePayloadCreator<SiteTakeover404Payload>(
    SiteTakeover404ViewedAnalyticsEvent,
  );
