import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "../../page/preorder/types";

export type CancelOrderClickedEvent = BasePreOrderAnalyticsPayload & {
  pageDetail: string;
};

const CancelPreorderClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.checkout,
  eventName: "Order Cancel Clicked",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
  clickActivity: "nvs:cancel order clicked",
};

export const cancelPreorderButtonClickedEventPayload =
  eventTrackPayloadCreator<CancelOrderClickedEvent>(
    CancelPreorderClickedAnalyticsEvent,
  );
