import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import {
  CollectionProperties,
  EventData,
  EventPayload,
  TokenProperties,
} from "../../types";

export interface PrepareDownloadClickedTrackEventPayload extends EventPayload {
  token: TokenProperties;
  collections: CollectionProperties;
}

const PrepareDownloadClickedAnalyticsEvent: EventData = {
  pageDetail: "download",
  pageType: PAGE_TYPE.tlp,
  eventName: "Prepare Download Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createPrepareDownloadClickedTrackEventPayload =
  eventTrackPayloadCreator<PrepareDownloadClickedTrackEventPayload>(
    PrepareDownloadClickedAnalyticsEvent,
  );
