import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "./types";

const OrderConfirmationViewedAnalyticsEvent: EventData = {
  pageDetail: "Order Confirmation Page Viewed",
  pageType: PAGE_TYPE.checkout,
  eventName: "Order Confirmation Page Viewed",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const createOrderConfirmationViewedPageEventPayload =
  eventPagePayloadCreator<BasePreOrderAnalyticsPayload>(
    OrderConfirmationViewedAnalyticsEvent,
  );
