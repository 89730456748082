import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MarketingPagePayload {}

const MarketingPageViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.marketing,
  eventName: "Marketing Page Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createMarketingPageViewedPageEventPayload =
  eventPagePayloadCreator<MarketingPagePayload>(
    MarketingPageViewedAnalyticsEvent,
  );
