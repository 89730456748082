import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../../constants";
import { eventTrackPayloadCreator } from "../../../../helpers/eventPayloadCreator";
import { EventData } from "../../../../types";
import { BasePreOrderAnalyticsPayload } from "../../../page/preorder/types";

export type ItemSoldOutTrackEvent = BasePreOrderAnalyticsPayload;

const ItemSoldOutAnalyticsEvent: EventData = {
  pageDetail: "Hang Tight Page",
  pageType: PAGE_TYPE.checkout,
  eventName: "Item Sold out",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const OrderPendingItemSoldOutEventPayload =
  eventTrackPayloadCreator<ItemSoldOutTrackEvent>(ItemSoldOutAnalyticsEvent);
