import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { TokenTransferEventPayload } from "../../track/token-transfer/types";

const AnalyticsEventData: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.tokenTransfer,
  eventName: "Token Transfer Confirmation Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createTokenTransferConfirmationViewedPageEventPayload =
  eventPagePayloadCreator<TokenTransferEventPayload>(AnalyticsEventData);
