import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface EditAddressCTAClickedPayload extends EventPayload {
  content: {
    title: string;
  };
}

const EditAddressCtaClickedEventData: EventData = {
  eventName: "Edit Address CTA Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
  pageDetail: "order-history",
};

export const createEditAddressCTAClickedEventPayload =
  eventTrackPayloadCreator<EditAddressCTAClickedPayload>(
    EditAddressCtaClickedEventData,
  );
