import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AchievementsPayload {}

const AchievementsClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.achievements,
  eventName: "Achievements Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
  clickActivity: "nvs:achievements",
};

export const createAchievementsClickedTrackEventPayload =
  eventPagePayloadCreator<AchievementsPayload>(
    AchievementsClickedAnalyticsEvent,
  );
