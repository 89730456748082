import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface ApplyChangesCTAClickedPayload extends EventPayload {
  content: {
    title: string;
  };
}

const ApplyChangesCtaClickedEventData: EventData = {
  eventName: "Apply Changes CTA Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
  pageDetail: "order-history",
};

export const createApplyChangesCTAClickedEventPayload =
  eventTrackPayloadCreator<ApplyChangesCTAClickedPayload>(
    ApplyChangesCtaClickedEventData,
  );
