import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface VirtualAvatarViewUpdatedTrackEventPayload
  extends EventPayload {
  products: [
    {
      name: string;
    },
  ];
}

export const createVirtualAvatarViewUpdatedTrackEventPayload = (
  eventData: EventData,
) => {
  return eventTrackPayloadCreator<VirtualAvatarViewUpdatedTrackEventPayload>(
    eventData,
  );
};
