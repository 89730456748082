import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderHistoryViewedPayload {}

const OrderHistoryViewedAnalyticsEvent: EventData = {
  pageDetail: "Order History",
  pageType: PAGE_TYPE.orderHistory,
  eventName: "Order History Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createOrderHistoryViewedPageEventPayload =
  eventPagePayloadCreator<OrderHistoryViewedPayload>(
    OrderHistoryViewedAnalyticsEvent,
  );
