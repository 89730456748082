import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../../constants";
import { eventTrackPayloadCreator } from "../../../../helpers/eventPayloadCreator";
import { EventData } from "../../../../types";
import { BasePreOrderAnalyticsPayload } from "../../../page/preorder/types";

export type CheckoutStartedTrackEvent = BasePreOrderAnalyticsPayload;

const CheckoutStartedAnalyticsEvent: EventData = {
  pageDetail: "Hang Tight Page",
  pageType: PAGE_TYPE.checkout,
  eventName: "Checkout Started",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const OrderPendingCheckoutStartedAnalyticsEventPayload =
  eventTrackPayloadCreator<CheckoutStartedTrackEvent>(
    CheckoutStartedAnalyticsEvent,
  );
