import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { TokenTransferEventPayload } from "./types";

const TokenTransferInitiatedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.tokenTransfer,
  eventName: "Token Transfer Initiated",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createTokenTransferInitiatedTrackEventPayload =
  eventTrackPayloadCreator<TokenTransferEventPayload>(
    TokenTransferInitiatedAnalyticsEvent,
  );
