import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import {
  CollectionProperties,
  EventData,
  EventPayload,
  TokenProperties,
} from "../../types";

export interface ThreeDFileDownloadViewedPageEventPayload extends EventPayload {
  token: TokenProperties;
  collections: CollectionProperties;
}

const ThreeDFileDownloadViewedAnalyticsEvent: EventData = {
  pageDetail: "download",
  pageType: PAGE_TYPE.tlp,
  eventName: "3D File Download Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const create3DFileDownloadViewedPageEventPayload =
  eventPagePayloadCreator<ThreeDFileDownloadViewedPageEventPayload>(
    ThreeDFileDownloadViewedAnalyticsEvent,
  );
