import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
import { getPageTypeFromPathname } from "../../helpers/helpers";
import { CLASSIFICATION_TYPE } from "../../constants";

export interface FAQClickedTrackEventPayload extends EventPayload {
  content: {
    title: string;
  };
}

const FAQClickedTrackEventData: EventData = {
  pageType: getPageTypeFromPathname(),
  eventName: "FAQ Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createFAQClickedTrackEventPayload = () => {
  return eventTrackPayloadCreator<FAQClickedTrackEventPayload>(
    FAQClickedTrackEventData,
  );
};
