import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
export interface GameQuestCompletedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
  message: {
    title: string;
    body: string;
  };
}

const GameQuestCompletedViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Quest Completed Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameQuestCompletedViewedPageEventPayload =
  eventPagePayloadCreator<GameQuestCompletedPayload>(
    GameQuestCompletedViewedAnalyticsEvent,
  );
