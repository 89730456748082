import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface ExampleTrackEventPayload extends EventPayload {
  exampleId: string;
  exampleName: string;
}

const ExampleAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.pdp,
  eventName: "",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createExampleTrackEventPayload =
  eventTrackPayloadCreator<ExampleTrackEventPayload>(ExampleAnalyticsEvent);
