import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { getPageTypeFromPathname } from "../../helpers/helpers";
import { EventData, EventPayload } from "../../types";

const IPGExploreMoreTabClickedAnalyticsEvent: EventData = {
  pageType: getPageTypeFromPathname(),
  eventName: "Explore Features Tab Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export interface IPGExploreMoreTabClickedPayload extends EventPayload {
  content: {
    title: string;
  };
}

export const createIPGExploreMoreTabClickedTrackEventPayload =
  eventTrackPayloadCreator<IPGExploreMoreTabClickedPayload>(
    IPGExploreMoreTabClickedAnalyticsEvent,
  );
