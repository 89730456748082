import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { TokenTransferEventPayload } from "./types";

const AnalyticsEventData: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.tokenTransfer,
  eventName: "Receiver Wallet Address Confirmed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createReceiverWalletAddressConfirmedTrackEventPayload =
  eventTrackPayloadCreator<TokenTransferEventPayload>(AnalyticsEventData);
