import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface NavAnalyticsPayload extends EventPayload {
  content: {
    title: string;
  };
}

const GlobalNavAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.globalNav,
  eventName: "Global Nav Page Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGlobalNavTrackEventPayload =
  eventTrackPayloadCreator<NavAnalyticsPayload>(GlobalNavAnalyticsEvent);
