import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GameInitializationPayload {}

const GameInitializationViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.glitchGalaxy,
  eventName: "Game Initialization Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameInitializationViewedPageEventPayload =
  eventPagePayloadCreator<GameInitializationPayload>(
    GameInitializationViewedAnalyticsEvent,
  );
