import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface ExamplePageEventPayload extends EventPayload {
  exampleId: string;
  exampleName: string;
}

const ExampleAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.pdp,
  eventName: "",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createExamplePageEventPayload =
  eventPagePayloadCreator<ExamplePageEventPayload>(ExampleAnalyticsEvent);
