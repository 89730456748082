import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface VirtualAvatarFitUpdatedTrackEventPayload extends EventPayload {
  products: [
    {
      name: string;
    },
  ];
}

export const createVirtualAvatarFitUpdatedTrackEventPayload = (
  eventData: EventData,
) => {
  return eventTrackPayloadCreator<VirtualAvatarFitUpdatedTrackEventPayload>(
    eventData,
  );
};
