import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../../types";

const AnalyticsEventData: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.tokenTransfer,
  eventName: "Token Transfer FAQs Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createTokenTransferFAQsClickedTrackEventPayload =
  eventTrackPayloadCreator<EventPayload>(AnalyticsEventData);
