import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface GameCompletedPayload extends EventPayload {
  game: {
    playTime: number;
  };
}

const GameCompletedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.glitchGalaxy,
  eventName: "Game Completed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameCompletedTrackEventPayload =
  eventPagePayloadCreator<GameCompletedPayload>(GameCompletedAnalyticsEvent);
