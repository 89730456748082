import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { getPageTypeFromPathname } from "../../helpers/helpers";
import { EventData, EventPayload } from "../../types";

const IPG3DModelInteractedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: getPageTypeFromPathname(),
  eventName: "3D Model Interacted",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createIPG3DModelInteractedTrackEventPayload =
  eventTrackPayloadCreator<EventPayload>(IPG3DModelInteractedAnalyticsEvent);
