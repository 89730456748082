import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";

export interface GameQuestQuestionPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
  message: {
    title: string;
    body: string;
  };
}

const GameQuestQuestionViewedAnalyticsEvent: EventData = {
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Game Quest Question Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createGameQuestQuestionViewedPageEventPayload =
  eventPagePayloadCreator<GameQuestQuestionPayload>(
    GameQuestQuestionViewedAnalyticsEvent,
  );
