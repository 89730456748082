import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import {
  CollectionProperties,
  EventData,
  EventPayload,
  ProductProperties,
} from "../../types";

export interface ProductClickedPageEventPayload extends EventPayload {
  products: ProductProperties;
  collections: CollectionProperties;
}

const ProductClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.cdp,
  eventName: "Product Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
  clickActivity: "nvs:product",
};

export const createProductClickedPageEventPayload =
  eventTrackPayloadCreator<ProductClickedPageEventPayload>(
    ProductClickedAnalyticsEvent,
  );
