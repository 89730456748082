import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import {
  CollectionProperties,
  EventData,
  EventPayload,
  ProductProperties,
} from "../../types";

export interface CarouselProductClickedPageEventPayload extends EventPayload {
  products: ProductProperties;
  collections: CollectionProperties;
}

const CarouselProductClickedAnalyticsEvent: EventData = {
  pageDetail: "carousel",
  pageType: PAGE_TYPE.cdp,
  eventName: "Carousel Product Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
  clickActivity: "nvs:carousel product clicked",
};

export const createCarouselProductClickedPageEventPayload =
  eventTrackPayloadCreator<CarouselProductClickedPageEventPayload>(
    CarouselProductClickedAnalyticsEvent,
  );
