import { CLASSIFICATION_TYPE, PAGE_TYPE } from "../../../constants";
import { eventTrackPayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { TokenTransferEventPayload } from "./types";

const MfaCodeRedeemedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.tokenTransfer,
  eventName: "MFA Code Redeemed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createMfaCodeRedeemedTrackEventPayload =
  eventTrackPayloadCreator<TokenTransferEventPayload>(
    MfaCodeRedeemedAnalyticsEvent,
  );
