import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload, QuickBuyProduct } from "../../types";

export interface ProductPageClickedPayload extends EventPayload {
  game: {
    playTime: number;
  };
  content: {
    title: string;
  };
  products: QuickBuyProduct;
}

const ProductPageClickedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.lowPoly,
  eventName: "Product Page Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createProductPageClickedPageEventPayload =
  eventPagePayloadCreator<ProductPageClickedPayload>(
    ProductPageClickedAnalyticsEvent,
  );
