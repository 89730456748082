import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../../constants";
import { eventPagePayloadCreator } from "../../../helpers/eventPayloadCreator";
import { EventData } from "../../../types";
import { BasePreOrderAnalyticsPayload } from "./types";

export type OrderCancellationModalViewedEvent = BasePreOrderAnalyticsPayload & {
  pageDetail: string;
};

const OrderCancellationModalViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.checkout,
  eventName: "Order Cancel Modal Viewed",
  classification: CLASSIFICATION_TYPE.coreBuyFlow,
};

export const createCancellationModalViewedPageEventPayload =
  eventPagePayloadCreator<OrderCancellationModalViewedEvent>(
    OrderCancellationModalViewedAnalyticsEvent,
  );
