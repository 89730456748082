import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LowPolyExperienceInitializedPayload {}

const LowPolyExperienceInitializedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.marketing,
  eventName: "Game Initialized",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createLowPolyExperienceInitializedTrackEventPayload =
  eventPagePayloadCreator<LowPolyExperienceInitializedPayload>(
    LowPolyExperienceInitializedAnalyticsEvent,
  );
