import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
import { getPageTypeFromPathname } from "../../helpers/helpers";

export interface HeroBlockPrimaryCtaTrackEventPayload extends EventPayload {
  content: {
    title: string;
  };
}

const HeroBlockPrimaryCtaClickedTrackEventData: EventData = {
  pageDetail: "",
  pageType: getPageTypeFromPathname(),
  eventName: "Hero Primary CTA Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createHeroBlockPrimaryCTAClickedEventPayload =
  eventTrackPayloadCreator<HeroBlockPrimaryCtaTrackEventPayload>(
    HeroBlockPrimaryCtaClickedTrackEventData,
  );
