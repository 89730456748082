import {
  createSchemaLink,
  getPageName,
  getGlobalProperties,
  slugify,
} from "./helpers";
import { Context, EventData, EventPayload } from "../types";
import { EVENT_TYPE, PAGE_TYPE } from "../constants";

export function eventPagePayloadCreator<T extends EventPayload>(
  event: EventData,
) {
  return eventPayloadCreator<T>(event, EVENT_TYPE.page);
}

export function eventTrackPayloadCreator<T extends EventPayload>(
  event: EventData,
) {
  return eventPayloadCreator<T>(event, EVENT_TYPE.track);
}

export function eventPayloadCreator<T extends EventPayload>(
  event: EventData,
  eventType: EVENT_TYPE,
) {
  // Return a function that creates the payload object based on a event specific payload and any required contexts
  return function createEventPayload(context: Context, payload: T) {
    /**
     * NOTE: Page detail & type can either be hard code in the Event Data,
     * or passed a dynamic value in the Event Payload
     */
    const pageDetail = payload.pageDetail || event.pageDetail;
    const pageType = payload.pageType ?? event.pageType ?? ("" as PAGE_TYPE);

    // Also, let's figure out how to more strictly type the return type if possible... Perhaps Nike has some types we can use in their package?

    return {
      name: event.eventName,
      options: {
        $schema: createSchemaLink(
          event.classification,
          pageType,
          eventType,
          slugify(event.eventName) || "",
        ),
      },
      properties: {
        eventName: event.eventName,
        clickActivity: (event.clickActivity && event.clickActivity) || "",
        view: {
          experienceType: "nvs",
          pageDetail: pageDetail,
          pageType: pageType,
          pageName: getPageName(pageType, pageDetail),
        },
        previousView: context.previousView,

        // use provided context to create the global properties
        ...getGlobalProperties(
          context.country,
          context.user,
          context.anonymousId,
          context.os,
        ),

        // send any custom properties
        ...payload,
      },
    };
  };
}
