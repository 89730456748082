import { CLASSIFICATION_TYPE } from "../../constants";
import { eventTrackPayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData, EventPayload } from "../../types";
import { getPageTypeFromPathname } from "../../helpers/helpers";

export interface HeroBlockSecondaryCtaTrackEventPayload extends EventPayload {
  content: {
    title: string;
  };
}

const HeroBlockSecondaryCtaClickedTrackEventData: EventData = {
  pageType: getPageTypeFromPathname(),
  eventName: "Hero Secondary CTA Clicked",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createHeroBlockSecondaryCTAClickedEventPayload =
  eventTrackPayloadCreator<HeroBlockSecondaryCtaTrackEventPayload>(
    HeroBlockSecondaryCtaClickedTrackEventData,
  );
