import clsx from "clsx";
import { PropsWithChildren } from "react";
import { IconContainer } from "../IconContainer";
import { IconButtonProps } from "./types";
import { getIconButtonStyles } from "./helpers";

export function IconButton({
  buttonType,
  className,
  children,
  isActive,
  iconContainerProps,
  ...props
}: PropsWithChildren<IconButtonProps>) {
  return (
    <button
      className={clsx(getIconButtonStyles({ buttonType, isActive }), className)}
      {...props}
    >
      <IconContainer {...iconContainerProps}>{children}</IconContainer>
    </button>
  );
}
