import { PAGE_TYPE, CLASSIFICATION_TYPE } from "../../constants";
import { eventPagePayloadCreator } from "../../helpers/eventPayloadCreator";
import { EventData } from "../../types";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MarketingPage3DModelPayload {}

const MarketingPage3DModelViewedAnalyticsEvent: EventData = {
  pageDetail: "",
  pageType: PAGE_TYPE.marketing3DModel,
  eventName: "Marketing Page 3D Model Viewed",
  classification: CLASSIFICATION_TYPE.experienceEvent,
};

export const createMarketingPage3DModelViewedPageEventPayload =
  eventPagePayloadCreator<MarketingPage3DModelPayload>(
    MarketingPage3DModelViewedAnalyticsEvent,
  );
